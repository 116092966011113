// .forgotpass-page {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   min-height: 60vh;
//   .info-container {
//     background: rgba(0, 0, 0, 0.5);
//     padding: 20px;
//     border-radius: 15px;
//     box-shadow: 5px 5px 15px;
//     .logo-container {
//       width: 350px;
//       .img-fluid {
//         width: 100%;
//       }
//     }
//   }
//   .background {
//     overflow-x: inherit;
//     width: 100%;
//     min-height: 100vh;
//     height: 100vh;
//     justify-content: center; //flex-end;
//     align-items: center;
//     position: relative;
//     flex-direction: column;
//     .temp {
//       flex-direction: column;
//       padding: 60px 30px;
//       border-radius: 20px;
//     }
//     .img-fluid {
//       width: 300px;
//     }
//   }
// }
.slider-container {
  height: 100%; // Ensure the container takes up full height
  .carousel {
    width: 100%; // Full width
    height: 100%; // Full height of the container
  }
  .carousel .slide img {
    width: 100%; // Scale the images to fit container width
    height: auto; // Let height adjust proportionally
    max-height: 95vh; // Limit image height for large screens
    object-fit: cover; // Ensures images are properly centered and cover the area
  }
}

.slider-container {
  position: relative;
  height: 100vh; /* Full-height for vertical centering */
}
