.singleselect-targeting {
}

.multiselect-targeting {
}

.include-exclude-targeting {
  .include-exclude-list {
    height: calc(100% - 80px);
  }
}
.LI-creation-tab {
  min-height: calc(100vh - 300px);
}
.LI-trageting-tab {
  height: calc(100vh - 350px);
}

.MuiListItemIcon-root {
  min-width: 0px !important;
}
.creative-section {
  position: relative;
  width: 100%;
  .creative-title {
    position: absolute;
    top: -11px;
    background: #fff;
    width: auto;
    padding: 0 10px;
    margin-left: -10px;
    font-size: 1.2em;
    font-weight: bold;
  }
}

.iconsColor {
  fill: #d63 !important;
}
/* styles.css */
.include-exclude-button {
  background-color: rgb(94, 238, 94); /* Green for Include and Exclude buttons */
  color: white;
}

.remove-button {
  background-color: red; /* Red for Remove button */
  color: white;
}

/* Optional: If you want to add a hover effect */
.include-exclude-button:hover,
.remove-button:hover {
  opacity: 0.8; /* Slightly fade on hover */
}
.multiselect-targeting {
  max-height: 300px; /* Adjust as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 10px; /* Optional padding for better appearance */
  border: 1px solid #ccc; /* Optional border for visual separation */
  border-radius: 4px; /* Optional rounded corners */
}
.list-item {
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.list-item.selected {
  font-weight: bold;
}

// .child-item {
//   background-color: #ffffff;
//   border-radius: 4px;
//   padding: 8px;
//   transition: background-color 0.2s ease;
// }

.child-item:hover {
  background-color: #f5f5f5;
}

.count-element {
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8rem !important;
}

.include-color {
  color: #4caf50;
}
.exclude-color {
  color: #f44336;
}

.bg-include-color {
  background-color: #4caf50;
}
.bg-exclude-color {
  background-color: #f44336;
}

.customContainer {
  background-color: white;
  padding: 8px;
  border-radius: 4px;
}

.customContainer:hover {
  background-color: #f5f5f5;
}

.parentChildbg {
  background-color: #f5f5f5;
}
