.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  z-index: 2;
  .spinner-bg {
    background: rgb(255, 255, 255);
    box-shadow: 0 0 20px 0 var(--gray-placeholder);
    border-radius: 50%;
    padding: 0.5rem;
    position: relative;
    display: flex;
  }
  .spinner__text {
    padding: 5px;
  }
  .spinner__desc {
    margin-left: 1rem;
    width: 338px;
  }
}

.tab-view-loader {
  top: auto !important;
  left: 94px;
  transform: none !important;
  height: calc(100% - 85px);
  width: calc(100% - 70px);
  margin-left: -24px;
  margin-top: -24px;
}
.loading {
  color: #fff; /* Explicitly setting text color to white */
  margin-left: 16px; /* Add some space between the loader and the text */
}
.loaderColor {
  color: #fcae73 !important;
}
