/* Add this to your CSS file */
.my-custom-cell {
  position: relative;
  display: inline-block;
  margin-left: 15px; /* Adjust the margin as needed */
  // margin-top: 40px; /* Adjust the margin as needed */
}
.campaign-name {
  word-wrap: break-word; /* Ensure long names wrap */
  overflow-wrap: break-word; /* Ensure long names wrap */
  max-width: 50px; /* Optional: limit width */
  white-space: normal; /* Allow text to wrap */
}
