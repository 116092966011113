.container-fluid {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1280px;
  padding-bottom: 4rem;
}

.container-shrink {
  padding-left: 190px !important;
}

.layout-container {
  transition: all 0.5s 0.5s ease-in-out;
  height: calc(100vh - 42px);
  width: calc(100vw - 70px);
}

.header-fluid {
  height: auto;
  background-color: #fcfcfd;
  border-bottom: 1px solid #e7e7ef;
}

.header-container,
.content-container {
  background: none !important;
  border: none !important;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 16px;
  padding-bottom: 20px;
  max-width: 100%;
  margin: 0 auto;
}

.header-container {
  h1 {
    color: #24242e;
    font-size: 24px;
    margin-bottom: 4px;
    line-height: 32px;
  }
  small,
  span {
    font-size: 14px;
  }
  .header-switch .body-text-md {
    width: 50px;
  }
  [class='MuiRating-'],
  [class^='MuiRating-'] {
    font-size: 1.5rem !important;
  }
  .circle-text {
    font-size: 0.8rem;
    font-weight: normal;
  }
  .portfolio {
    max-width: 260px;
  }
}

.header-child {
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0 !important;
  }
}

.no-bg {
  background-color: unset;
  border-bottom: unset;
}
