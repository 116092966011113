.graph-container {
  position: relative;
  height: 400px;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.max-height-400 {
  max-height: 400px;
}
.selected-items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.selected-item {
  background-color: #fce5dc;
  border-radius: 16px;
  padding: 2px 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.selected-item button {
  background: none;
  border: none;
  color: #f00;
  cursor: pointer;
  font-size: 16px;
  margin-left: 8px;
}

/* Media Queries */
@media (max-width: 768px) {
  .select-container {
    flex-direction: column; /* Stack elements vertically on tablets */
  }

  .select-container .selected-items {
    flex-direction: column; /* Stack selected items vertically */
  }

  .selected-item {
    max-width: 100%; /* Ensure items are full-width on smaller screens */
  }
}

@media (max-width: 480px) {
  .selected-item {
    font-size: 14px; /* Adjust font size for very small screens */
    padding: 2px 6px; /* Adjust padding */
  }

  .selected-item button {
    font-size: 14px; /* Adjust button font size */
  }
}
