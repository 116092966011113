.tnc {
  bottom: 0;
  height: 2.6rem; /* Fixed height */
  flex-shrink: 0;
  text-align: left;
  border-top: 1px solid var(--border);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  color: rgba(36, 36, 46, 0.52);
  background-color: var(--white);
  line-height: 1.2;
  font-size: 1.75rem; /* Reduced font size for smaller height */
  padding: 1rem 0 0.5rem 1rem; /* Adjusted padding */
  border-top: 1px solid #e7e7e9;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure everything fits horizontally */

  &.sidebar-expanded {
    left: 260px;
    width: calc(100% - 260px);
    padding: 0.5rem 2rem;
  }
  &.sidebar-collapsed {
    left: 70px;
    width: calc(100% - 70px);
    padding: 0.5rem 2rem;
  }

  .footer-link {
    color: var(--secondary);
    display: inline-flex;
    align-items: center;
    &:not(:first-child) {
      margin: 0 0.25rem;
    }
    &:not(:last-child) {
      &:after {
        content: '';
        display: inline-block;
        background: var(--gray);
        height: 12px;
        width: 1px;
        margin: 0.5rem;
      }
    }
  }
}
