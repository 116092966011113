* {
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

@media print {
}

.invoice-box {
  max-width: 800px;
  zoom: 65%;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #ffefef;
}
.invoice-box p {
  font-size: 15px;
}
.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.total {
  text-align: right;
  padding-top: 20px;
  margin-top: 10px;
}
.totalleft {
  text-align: left;
  padding-top: 20px;
  margin-top: 10px;
}
.invoice-box hr {
  border: none;
  height: 2px;
  background-color: #333;
}

.invoice-box hr::before {
  content: '';
  display: block;
  height: 2px;
  background-color: #333;
  margin-top: 10px;
}
.totalbalance {
  position: relative;
}

.totalbalance::before {
  content: '';
  position: absolute;
  top: -10px;
  bottom: 0;
  left: 400px;
  right: 0;
  z-index: -1;
}

.invoice-box table td {
  padding: 10px;
  vertical-align: top;
}
.billtoinfo {
  text-align: left !important;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 30px;
  // margin-bottom: 110px;
}

.invoice-box table tr.heading td {
  background: #000000;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(6) {
  border-top: 2px solid #eee;
  font-weight: bold;
}
.long-address {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.bill-to td {
  width: 50%; /* Set both <td> elements to take up 50% of the table width */
}
.invoice-container {
  position: relative; /* Set position relative */
}

.print-button {
  position: absolute; /* Set position absolute */
  top: 0; /* Align button to the top */
  right: 0; /* Align button to the right */
  margin-top: 10px; /* Adjust top margin as needed */
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}
