.preview-page {
  .preview-wrapper {
    margin: 22px auto;
    .MuiSkeleton-text {
      transform: scale(1, 1) !important;
    }
    .mobile_app_wrapper {
      position: relative;
      width: 350px;
      height: 777px;
    }
    .tablet_app_wrapper {
      position: relative;
      width: 700px;
      height: 902px;
    }
    .desktop_app_wrapper {
      position: relative;
      width: 1000px;
      height: 700px;
    }

    .banner_image {
      position: absolute;
      border: 2px dashed #f00;
    }

    .BANNER_120_240 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 950px;
        height: 500px;
        top: 29px;
        left: 25px;
        position: absolute;
        opacity: 0.6;
      }
      .banner_image {
        top: 150px;
        height: 240px;
        width: 120px;
        left: 850px;
      }
      .banner_image.case2 {
        top: 150px;
        height: 240px;
        width: 120px;
        left: 26px;
      }
    }

    .BANNER_120_600 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 970px;
        height: 500px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .banner_image {
        top: 145px;
        height: 320px;
        width: 75px;
        left: 25px;
      }
    }

    .BANNER_160_600 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 970px;
        height: 525px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .banner_image {
        top: 77px;
        height: 425px;
        width: 102px;
        left: 66px;
      }
    }

    .BANNER_180_150 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 325px;
        height: 710px;
        top: 13px;
        left: 12px;
        border-radius: 40px;
        position: absolute;
        opacity: 0.75;
      }
      .app_image1 {
        width: 970px;
        height: 525px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .banner_image {
        bottom: 190px;
        height: 150px;
        width: 180px;
        left: 85px;
      }
      .banner_image.case2 {
        bottom: 180px;
        height: 150px;
        width: 180px;
        left: 615px;
      }
    }

    .BANNER_200_200 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 319px;
        top: 12px;
        left: 12px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .app_image1 {
        width: 970px;
        height: 500px;
        top: 24px;
        left: 26px;
        position: absolute;
        opacity: 0.6;
      }
      .banner_image {
        top: 430px;
        height: 200px;
        width: 200px;
        left: 75px;
      }
      .banner_image.case2 {
        top: 160px;
        height: 200px;
        width: 200px;
        left: 35px;
      }
      .banner_image.case3 {
        top: 160px;
        height: 200px;
        width: 200px;
        left: 300px;
      }
      .banner_image.case4 {
        top: 107px;
        height: 200px;
        width: 200px;
        left: 679px;
      }
    }

    .BANNER_250_250 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 320px;
        top: 9px;
        left: 15px;
        border-radius: 40px;
        position: absolute;
        opacity: 0.75;
      }
      .app_image1 {
        width: 970px;
        height: 500px;
        top: 24px;
        left: 26px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image2 {
        width: 650px;
        height: 840px;
        top: 27px;
        left: 24px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image {
        bottom: 128px;
        height: 250px;
        width: 250px;
        left: 50px;
      }
      .banner_image.case2 {
        bottom: 360px;
        height: 250px;
        width: 250px;
        left: 33px;
      }
      .banner_image.case3 {
        bottom: 360px;
        height: 250px;
        width: 250px;
        left: 300px;
      }
      .banner_image.case4 {
        bottom: 393px;
        height: 250px;
        width: 250px;
        left: 639px;
      }
      .banner_image.case5 {
        top: 365px;
        height: 250px;
        width: 250px;
        left: 238px;
      }
    }

    .BANNER_300_250 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 325px;
        top: 9px;
        left: 12px;
        border-radius: 40px;
        position: absolute;
        opacity: 0.75;
      }
      .app_image1 {
        width: 970px;
        height: 525px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image2 {
        width: 650px;
        height: 840px;
        top: 27px;
        left: 24px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image {
        bottom: 99px;
        height: 250px;
        width: 300px;
        left: 50px;
      }
      .banner_image.case2 {
        top: 340px;
        height: 250px;
        width: 300px;
        left: 25px;
      }
      .banner_image.case3 {
        top: 280px;
        height: 185px;
        width: 192px;
        left: 730px;
      }
      .banner_image.case4 {
        top: 366px;
        height: 250px;
        width: 300px;
        left: 200px;
      }
    }

    .BANNER_300_600 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 970px;
        height: 500px;
        top: 24px;
        left: 26px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image2 {
        width: 650px;
        height: 840px;
        top: 27px;
        left: 24px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image {
        bottom: 215px;
        height: 600px;
        width: 300px;
        left: 645px;
      }
      .banner_image.case2 {
        top: 128px;
        height: 600px;
        width: 300px;
        left: 360px;
      }
    }

    .BANNER_300_1050 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 970px;
        height: 500px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image1 {
        width: 650px;
        height: 840px;
        top: 27px;
        left: 24px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image {
        top: 50px;
        height: 450px;
        width: 120px;
        left: 855px;
      }
      .banner_image.case2 {
        top: 50px;
        height: 450px;
        width: 120px;
        left: 25px;
      }
      .banner_image.case3 {
        top: 125px;
        height: 656px;
        width: 300px;
        left: 365px;
      }
    }

    .BANNER_320_50 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 325px;
        top: 9px;
        left: 12px;
        border-radius: 40px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image {
        top: 344px;
        height: 50px;
        width: 315px;
        left: 19px;
      }
    }

    .BANNER_320_100 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image1 {
        width: 970px;
        height: 500px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image {
        width: 325px;
        top: 9px;
        left: 12px;
        border-radius: 40px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image {
        top: 320px;
        height: 100px;
        width: 320px;
        left: 15px;
      }
      .banner_image.case2 {
        top: 25px;
        height: 100px;
        width: 320px;
        left: 315px;
      }
    }

    .BANNER_320_320 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 325px;
        top: 9px;
        left: 12px;
        border-radius: 40px;
        position: absolute;
        opacity: 0.75;
      }
      .app_image1 {
        width: 970px;
        height: 500px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image2 {
        width: 650px;
        height: 840px;
        top: 27px;
        left: 24px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image.case2 {
        top: 210px;
        height: 300px;
        width: 320px;
        left: 300px;
      }
      .banner_image.case3 {
        top: 360px;
        height: 280px;
        width: 320px;
        left: 185px;
      }
      .banner_image {
        top: 340px;
        height: 320px;
        width: 320px;
        left: 15px;
      }
    }

    .BANNER_320_480 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 325px;
        top: 9px;
        left: 12px;
        border-radius: 40px;
        position: absolute;
        opacity: 0.75;
      }
      .app_image1 {
        width: 970px;
        height: 525px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image2 {
        width: 650px;
        height: 840px;
        top: 27px;
        left: 24px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }

      .banner_image.case3 {
        top: 128px;
        height: 480px;
        width: 320px;
        left: 336px;
      }

      .banner_image {
        top: 96px;
        height: 480px;
        width: 320px;
        left: 15.5px;
      }
      .banner_image.case2 {
        top: 70px;
        height: 450px;
        width: 260px;
        left: 635px;
      }
    }

    .BANNER_336_280 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image1 {
        width: 970px;
        height: 500px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image2 {
        width: 650px;
        height: 840px;
        top: 27px;
        left: 24px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .app_image {
        width: 325px;
        top: 9px;
        left: 12px;
        border-radius: 40px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image {
        top: 108px;
        height: 228px;
        width: 336px;
        left: 585px;
      }
      .banner_image.case2 {
        top: 198px;
        height: 228px;
        width: 336px;
        left: 25px;
      }
      .banner_image.case3 {
        top: 380px;
        height: 228px;
        width: 336px;
        left: 185px;
      }
    }

    .BANNER_468_60 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 325px;
        top: 9px;
        left: 12px;
        border-radius: 40px;
        position: absolute;
        opacity: 0.75;
      }
      .app_image1 {
        width: 950px;
        height: 500px;
        top: 30px;
        left: 25px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image2 {
        width: 650px;
        height: 840px;
        top: 28px;
        left: 24px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image {
        top: 332px;
        height: 60px;
        width: 468px;
        left: 24px;
      }
      .banner_image.case2 {
        top: 36px;
        height: 60px;
        width: 480px;
        left: 330px;
      }
      .banner_image.case3 {
        top: 765px;
        height: 60px;
        width: 468px;
        left: 110px;
      }
    }

    .BANNER_480_320 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image1 {
        width: 970px;
        height: 525px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image {
        width: 325px;
        top: 9px;
        left: 12px;
        border-radius: 40px;
        position: absolute;
        opacity: 0.75;
      }
      .app_image2 {
        width: 650px;
        height: 840px;
        top: 27px;
        left: 24px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image {
        top: 215px;
        height: 200px;
        width: 320px;
        left: 298px;
      }
      .banner_image.case2 {
        top: 200px;
        height: 231px;
        width: 276px;
        left: 35px;
      }
      .banner_image.case3 {
        top: 360px;
        height: 320px;
        width: 480px;
        left: 110px;
      }
    }

    .BANNER_728_90 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 642px;
        top: 27px;
        left: 29px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .app_image1 {
        width: 970px;
        height: 525px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .banner_image {
        bottom: 60px;
        height: 90px;
        width: 728px;
        left: 26px;
      }
      .banner_image.case2 {
        top: 333px;
        height: 90px;
        width: 728px;
        left: 135px;
      }
    }

    .BANNER_768_1024 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 970px;
        height: 500px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image1 {
        width: 643px;
        height: 840px;
        top: 27px;
        left: 26px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image.case2 {
        top: 50px;
        height: 450px;
        width: 200px;
        left: 745px;
      }
      .banner_image {
        top: 120px;
        height: 700px;
        width: 730;
        left: 35px;
      }
    }

    .BANNER_970_90 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 970px;
        height: 500px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image2 {
        width: 650px;
        height: 840px;
        top: 27px;
        left: 24px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image {
        top: 78px;
        height: 90px;
        width: 920px;
        left: 40px;
      }
      .banner_image.case2 {
        top: 200px;
        height: 90px;
        width: 970px;
        left: 33px;
      }
    }

    .BANNER_970_250 {
      .frame_image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .app_image {
        width: 970px;
        height: 500px;
        top: 24px;
        left: 15px;
        position: absolute;
        opacity: 0.6;
      }
      .app_image2 {
        width: 650px;
        height: 840px;
        top: 27px;
        left: 24px;
        border-radius: 25px;
        position: absolute;
        opacity: 0.75;
      }
      .banner_image {
        top: 235px;
        height: 250px;
        width: 970px;
        left: 185px;
      }
      .banner_image.case2 {
        top: 195px;
        height: 250px;
        width: 970px;
        left: 33px;
      }
    }
  }
}

.view-buttons {
  top: -5px;
  left: 600px;
  position: absolute;
  display: flex;
  justify-content: space-around;
  margin: 5px;
}

.btn1 {
  padding: 5px 15px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-right: 10px;
  size: 100px;
  background-color: white;
}

.d-flex1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons {
  height: 50px;
}

.selected {
  // Add your styles for the selected button, e.g., background color or border

  padding: 5px 5px;
  margin-top: 2px;
  border: 2px solid black; // Example border style
}
