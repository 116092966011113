.text-field {
  position: relative;
  .MuiInputLabel-outlined {
    transform: translate(12px, -8px) scale(0.75);
    background: white;
    padding: 0px 2.5px 0px 3px;
  }
  .MuiOutlinedInput-input {
    padding: 9px 14px !important;
  }
}
.new-select {
  padding-top: 7.5px;
  padding-bottom: 8px;
  div[class*='-menu'] {
    margin-top: -8px;
    z-index: 2;
  }
  #react-select-2-placeholder,
  div[class*='-placeholder'] {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 0.875rem !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400 !important;
    line-height: 1;
    letter-spacing: 0.00938em;
  }
  div[class*='-placeholder'],
  div[class*='-singleValue'],
  div[class*='-option'] {
    font-size: 0.875rem !important;
    // padding: 6px 10px !important;
  }
}

.date-range-picker {
  position: relative;
  padding-top: 16px;
  padding-bottom: 8px;

  .label {
    display: flex;
    align-items: center;
    font-size: 12px !important;
    position: absolute;
    top: 8px;
    background: #fff;
    left: 9px;
    padding: 0 5px; // Changed padding to only horizontal
    height: 16px;
    border-radius: 4px; // Changed from 50% to 4px for slight rounding
    // or you can remove border-radius completely
  }
}

.flotting-label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-size: 12px; // Consistent font size for labels
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px !important;
  // padding: 5px !important;
  // position: absolute;
  // top: 6px;
  background: #fff;
  // left: 9px;
  // z-index: 1;
}

.infoIcon {
  color: #fcae73 !important;
  height: 12px !important;
}
.label-container {
  position: absolute;
  top: 6px; /* Adjust as needed to position the label correctly */
  left: 9px;
  z-index: 1;
  background: white;
  padding: 0 2px;
}

.MuiFormLabel-asterisk {
  // margin-left: 2px;
  color: red; // Adjust as necessary
}
.info-icon {
  padding: 2px !important;
  margin-left: 1px;

  .MuiSvgIcon-root {
    font-size: 16px; // Adjust icon size as needed
    color: rgba(0, 0, 0, 0.54); // Adjust icon color as needed
  }
}

.TooltipFontSize {
  color: #fff;
  font-size: 13px !important;
}
