.view,
.breakup {
  width: 50%;
}
.details input {
  width: 100%;
}

.detail {
  display: flex;
  justify-content: space-between;
}

.footer {
  display: flex;
  justify-content: space-between;
}

/*----- Styling the sliders ----*/
input[type='range'] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #fcae73;
  border-radius: 10px;
}

input[type='range']::-webkit-slider-thumb {
  box-shadow: 2px 2px 5px #b3b3b3, -1px -1px 3px grey;
  border: 5px solid #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fcae73;
  cursor: pointer;
  margin-top: -6px;
  -webkit-appearance: none;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #b3b3b3;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #fcae73;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
  border-radius: 10px;
}

input[type='range']::-moz-range-thumb {
  box-shadow: 2px 2px 5px #b3b3b3, -1px -1px 3px grey;
  border: 5px solid #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fcae73;
  cursor: pointer;
  margin-top: -6px;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #fcae73;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
  border-radius: 10px;
}

input[type='range']::-ms-fill-lower {
  background: #fcae73;
  border-radius: 5px;
}

input[type='range']::-ms-fill-upper {
  background: #fcae73;
  border-radius: 5px;
}

input[type='range']::-ms-thumb {
  box-shadow: 2px 2px 5px #b3b3b3, -1px -1px 3px grey;
  border: 5px solid #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fcae73;
  cursor: pointer;
  margin-top: -6px;
}

input[type='range']:focus::-ms-fill-lower {
  background: #fcae73;
}

input[type='range']:focus::-ms-fill-upper {
  background: #fcae73;
}

/*-------------------------------------*/
#price {
  color: #130f31;
  font-size: 25px;
}

.loan-details {
  width: 90%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

#price-container {
  color: #dd6633;
  margin-top: 50px;
}

#price-container::before {
  content: 'Monthly Payable:';
  font-size: 12px;
  display: block;
}
.loan-details {
  .chart-details {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;

    &:last-child {
      border-bottom: none;
    }

    .MuiTypography-root {
      flex: 1;
      margin: 0;
    }

    .MuiTypography-root:last-child {
      text-align: right;
    }
  }
}
.detail {
  // display: flex;
  align-items: center; /* Vertically center the items */
  justify-content: space-between; /* Place items with space between */
  width: 100%; /* Take full width of the parent */
}

.totalvalues {
  color: #130f31;
  fontsize: 17px;
}

.title-name {
  color: #dd6633;
}

.text-fields {
  width: 150px !important;
}

@media (max-width: 768px) {
  .chart-container {
    height: 300px; /* Adjust the height for mobile devices */
  }
}

@media (max-width: 480px) {
  .chart-container {
    height: 300px; /* Further adjust the height for smaller screens */
  }
}
