.tenant-admin {
  .MuiIconButton-root {
    margin-right: 12px;
    &.MuiAccordionSummary-expandIcon {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  .MuiTypography-root {
    a {
      font-weight: 900;
    }
  }
  .MuiTypography-root p {
    color: #b5b5ba;
    line-height: 0px;
    font-weight: 600;
    font-size: 11px;
  }
  button {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
  }
}

.tenant-admin:first-child {
  line-height: 1rem;
}

.logout {
  h6 {
    line-height: 16px;
  }
  p {
    line-height: 0px;
  }
  span {
    color: var(--red);
    font-size: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.tenant-admin .btn-primary,
.tenant-admin .show > .btn-primary.dropdown-toggle,
.tenant-admin .dropdown .btn-primary:focus {
  background-color: var(--white);
  border-color: var(--white);
  border-color: var(--white) !important;
  color: rgba(36, 36, 46, 0.72);
  box-shadow: none !important;
}

.MuiIconButton-label svg {
  color: black;
}
