.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  // height: auto;
  .temp {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 30px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    height: auto; /* Set height to auto */
    // overflow: auto;
    form {
      /* Add some padding/margin to the form if needed */
      padding: 10px;
      margin: 10px;
    }
  }

  .info-container {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 5px 5px 15px;

    .logo-container {
      width: 900px;
      height: auto;
      .img-fluid {
        height: auto;
        width: 100%;
      }
    }
  }
}

.textarea {
  width: 100%;
  minheight: 50px;
  color: var(--primary);
}

.logoImg {
  width: 300px;
  height: 150px;
  object-fit: contain;
  margin: auto;
}

.bgColor {
  background-color: #ffd580;
}
