.tenant-admin-nav .MuiIconButton-label svg {
  color: var(--white);
}
.tenant-admin-nav {
  .MuiIconButton-root {
    .MuiTouchRipple-root {
      display: none;
    }
  }
  .active-menu {
    .MuiAccordionSummary-root label {
      color: var(--white);
    }
  }
  .MuiIconButton-root {
    &:hover {
      background: transparent;
    }
    margin-right: 2rem;
  }
  .MuiListItemIcon-root {
    cursor: pointer;
    min-width: 3rem;
    display: flex;
    flex-direction: row;
    align-items: inherit;
    justify-content: inherit;
    padding-left: 0.4rem;
    span {
      font-size: 24px;
    }
    .img-fluid {
      box-sizing: border-box;
      height: 38px;
      width: 38px;
      border: 2px solid rgba(255, 255, 255, 0.16);
      border-radius: 19px;
      margin-left: -3px;
    }
  }

  .MuiAccordionSummary-root {
    background-color: var(--sidebar-bg) !important;
    border: none;
    font-size: 14px;
    width: auto;
    cursor: pointer;
    min-height: 42px !important;
    [class^='icon-']::before {
      color: rgba(255, 255, 255, 0.52);
    }
    .MuiAccordionSummary-content {
      margin: 0;
    }
    svg,
    g,
    path {
      color: rgba(255, 255, 255, 0.52);
    }
    label {
      padding: 0;
      margin: 0;
      color: rgba(255, 255, 255, 0.62);
      text-align: left;
      vertical-align: middle;
      display: table-cell;
      font-size: 14px;
      //font-weight: 500;
      line-height: 16px;
      cursor: pointer;
    }
    &:hover {
      label {
        color: rgba(255, 255, 255, 0.82);
      }
      [class^='icon-']::before {
        color: rgba(255, 255, 255, 1);
      }
      svg,
      g,
      path {
        color: rgba(255, 255, 255, 1);
      }
    }
    padding-left: 0.4rem;
    span {
      font-size: 24px;
    }
    // animation: cssAnimation 0.15s 0.15s forwards;
    //visibility: hidden;
    // opacity: 0.52;
  }
  .Mui-expanded {
    margin: 0 !important;
  }
  .MuiCollapse-wrapperInner {
    border: none;
    box-shadow: unset;
    ul {
      margin: 0;
      padding-left: 3.25rem;
      border: none;
      background-color: var(--sidebar-bg);
      list-style: none;
      color: rgba(255, 255, 255, 0.62);
      .btn-link {
        color: rgba(255, 255, 255, 0.62) !important;
      }
      li {
        padding: 0.5rem;
        margin-left: 1rem;
        button {
          font-size: 14px;
        }
      }
      li:hover {
        .btn-link {
          color: rgba(255, 255, 255, 0.82) !important;
        }
      }
    }
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
    .mui-icon {
      margin-left: 0.6rem !important;
    }
  }
}

.logo {
  // margin-left: -17.5rem;
  min-height: 32px;
  max-height: 32px;
  max-width: 120px;
  margin: 0 auto;
}

.logo-sqaure {
  height: 32px;
  width: 32px;
}

.tenant-admin-nav .MuiDrawer-root {
  width: 70px;
}

.tenant-admin-nav .MuiList-root {
  padding: 0px;
}

.tenant-admin-nav .MuiListItemIcon-root svg {
  color: #ffffff;
  //margin: 7px;
  height: auto;
  width: 2rem;
  g {
    transform: translate(6, 13.5);
    stroke: #ffffff;
    stroke-width: 2;
  }
}

.tenant-admin-nav .menu label {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.62);
  text-align: left;
  vertical-align: middle;
  display: table-cell;

  font-size: 14px;
  //font-weight: 500;
  line-height: 16px;
}

.tenant-admin-nav .MuiListItem-button {
  [class^='icon-']::before {
    color: rgba(255, 255, 255, 0.52);
  }
}

.tenant-admin-nav .MuiListItem-button {
  &:hover {
    background: none;
    label {
      color: rgba(255, 255, 255, 0.82);
    }
    [class^='icon-']::before {
      color: rgba(255, 255, 255, 1);
    }
  }
}

.tenant-admin-nav ul {
  .active-menu {
    border-left: 2px solid var(--primary);
    background-color: rgba(0, 0, 0, 0.12);
    &:hover {
      background-color: rgba(0, 0, 0, 0.12);
      label {
        color: var(--primary);
      }
      [class^='icon-']::before {
        color: var(--primary);
      }
    }
    [class^='icon-']::before {
      color: var(--primary);
    }
    .MuiListItemIcon-root {
      padding-left: 4px;
      span {
        font-size: 24px;
      }
    }
    span:before {
      color: var(--primary);
    }
    svg {
      color: var(--primary);
    }
    .menu {
      label {
        color: var(--primary);
      }
    }
  }
  .MuiCollapse-wrapperInner {
    .sub-active-menu {
      color: var(--white) !important;
    }
  }
  [activeclassname='active-menu'] {
    [class^='icon-']::before {
      color: rgba(255, 255, 255, 1);
    }
  }
  .dropdown {
    svg {
      color: var(--white);
      g {
        stroke: var(--white);
      }
    }
  }
}

.tenant-admin-nav .MuiPaper-root {
  overflow: unset;
  transition-timing-function: ease-in-out;
  transition: none;
  &.MuiAccordion-root {
    transition: none;
  }
}

.MuiListItem-root {
  height: 42px;
  .menu {
    color: #ffffff;
    animation: cssAnimation 0.15s 0.15s forwards;
    //visibility: hidden;
    opacity: 0.52;
  }
}

@keyframes cssAnimation {
  0% {
    //visibility: visible;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes cssAnimation {
  0% {
    //visibility: visible;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.notify .icon-notification::after {
  border: 2px solid var(--sidebar-bg);
  background: red;
  height: 9px;
  width: 9px;
  content: '';
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  left: 37px;
}

.light {
  .MuiIconButton-label svg {
    color: rgba(0, 0, 0, 1);
    g {
      stroke: rgba(0, 0, 0, 1);
    }
  }

  .tenant-admin-nav {
    .MuiListItemIcon-root {
      .img-fluid {
        border: 2px solid rgba(0, 0, 0, 0.16);
      }
    }

    .MuiAccordionSummary-root {
      background-color: var(--white);
      [class^='icon-']::before {
        color: rgba(0, 0, 0, 0.52);
      }
      svg,
      g,
      path {
        color: rgba(0, 0, 0, 0.52);
      }
      label {
        color: rgba(0, 0, 0, 0.62);
      }
      &:hover {
        label {
          color: rgba(0, 0, 0, 0.82) !important;
        }
        [class^='icon-']::before {
          color: rgba(0, 0, 0, 1);
        }
        svg,
        g,
        path {
          color: rgba(0, 0, 0, 1);
        }
      }

      //visibility: hidden;
      // opacity: 0.52;
    }

    .MuiCollapse-wrapperInner {
      ul {
        color: rgba(0, 0, 0, 0.62);
        .btn-link {
          color: rgba(0, 0, 0, 0.62) !important;
        }
        li:hover {
          .btn-link {
            color: rgba(0, 0, 0, 0.82) !important;
          }
        }
      }
    }
    .MuiPaper-elevation1 {
      box-shadow: none;
    }
  }

  .MuiIconButton-root svg {
    color: rgba(0, 0, 0, 1);
    g {
      stroke: rgba(0, 0, 0, 1);
    }
    path {
      color: rgba(0, 0, 0, 1);
    }
  }

  .tenant-admin-nav .menu label {
    color: rgba(0, 0, 0, 0.62);
  }

  .tenant-admin-nav .MuiListItem-button {
    [class^='icon-']::before {
      color: rgba(0, 0, 0, 0.52);
    }
  }

  .tenant-admin-nav .MuiListItem-button {
    &:hover {
      background: none;
      label {
        color: rgba(0, 0, 0, 0.82);
      }
      [class^='icon-']::before {
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  .tenant-admin-nav ul {
    .active-menu {
      border-left: 2px solid var(--primary);
      background-color: rgba(0, 0, 0, 0.1);
      label {
        color: rgba(0, 0, 0, 1);
        &:hover {
          color: rgba(0, 0, 0, 1);
        }
      }
      [class^='icon-']::before {
        color: var(--primary);
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        label {
          color: rgba(0, 0, 0, 1);
        }
        [class^='icon-']::before {
          color: var(--primary);
        }
      }
      .menu {
        label {
          color: rgba(0, 0, 0, 1);
        }
      }
    }
    [activeclassname='active-menu'] {
      [class^='icon-']::before {
        color: rgba(0, 0, 0, 1);
      }
      label {
        color: rgba(0, 0, 0, 1);
      }
    }
    .dropdown {
      svg {
        color: rgba(0, 0, 0, 1);
        g {
          stroke: rgba(0, 0, 0, 1);
        }
      }
    }
  }

  .MuiListItem-root {
    .menu {
      color: rgba(0, 0, 0, 1);
    }
  }
}

.MuiAccordion-root:before {
  display: none;
}
.notificationContainer {
  position: relative;
  .notificationCount {
    position: absolute;
    background: var(--primary);
    font-size: 12px !important;
    top: -8px;
    color: var(--white);
    border-radius: 50%;
    right: 10px;
    width: 20px;
    height: 20px;
    padding: 3px;
    text-align: center;
  }
}
.notificationPopup {
  width: 410px;
  .notification-items {
    border-left: 2.5px solid transparent;
    border-top: 1px solid var(--bg-border-color);
    position: relative;
    .notification-item {
      position: relative;
      z-index: 2;
    }
    .MuiListItemText-primary {
      line-height: 1;
      .notification-title {
        font-size: 13px;
        line-height: 1.5;
        .boldText {
          font-weight: 550;
          display: inline-block;
        }
      }
      .notification-subtitle {
        padding-top: 2px;
        display: block;
        color: var(--gray-dark);
      }
    }
    .MuiListItemText-secondary {
      font-size: 12px;
      margin-top: 0.25rem;
    }
  }
  .unreadNotification {
    border-left: 2.5px solid var(--primary);
    position: relative;
    .unreadNotification-overlay {
      background: var(--primary);
      opacity: 0.1;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
    }
  }

  .unreadNotification:hover {
    background: transparent;
    .unreadNotification-overlay {
      opacity: 0.15;
    }
  }
  .notification-items:hover {
    background: #f8f9fa;
  }
  .notificationPopupBody {
    max-height: 450px;
    overflow: auto;
  }
}

.notification-popover {
  .arrow {
    display: none;
  }
  z-index: 1200;
  .linkText {
    color: var(--primary);
    font-size: 13px;
    font-weight: normal;
    line-height: 1;
    &:hover {
      color: var(--primary);
    }
  }
}

.menu-sidebar {
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.mainAvatar svg {
  color: #dd6633 !important;
}
.mainAvatar {
  width: 32px !important;
  height: 32px !important;
}

.MuiAvatar-colorDefault {
  color: #ffffff;
  background-color: #ede9e9 !important;
}

.AvtarButton:hover {
  background-color: #fff !important;
}
