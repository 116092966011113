.showQR {
  margin: 5px auto;
  display: flex;
  justify-content: center;
  .Qr-Code {
    width: 31%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.qr-container {
  display: flex;
  align-items: center;
}

.show-Details {
  margin: 5px auto;
  width: 60%;
}

.tabRoot:hover {
  color: var(--primary) !important;
  cursor: pointer;
}

/* styles.css */

.dialogTitle {
  // background-color: #f8e6d7;
  color: white;
  text-align: center;
}

.popcolor {
  background-color: #fafafa !important;
}
.semi-bold {
  font-weight: 600;
}

.logos {
  width: 100%;
  height: 27px;
  margin-right: 10px;
  /* Add any other logo styling here */
}

.icons {
  color: #d63;
}

.editIcon {
  height: 15px;
  cursor: pointer;
}
/* External CSS file or style tag */

.close-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  z-index: 1; /* Ensure it appears above other content */
}

.textField {
  border: 1px solid #ced4da; /* Add your desired border color */
  border-radius: 4px; /* Add your desired border radius */
  padding: 8px; /* Add your desired padding */
}

.amountTextField {
  width: 150px;
  margin-left: 180px;
}

.gstandTotalAmountTextField {
  width: 145px;
  margin-left: 170px;
}

.centered-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%; /* Adjust the width as needed */
  margin: 0 auto; /* Center the form horizontally */
}

.form-field {
  width: 100%; /* Ensure fields take up the full width of the form */
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
