.dashboard-page {
  .chart-container {
    padding: 10px !important;
  }
  .pie-chart-container {
    width: 350px;
    height: 350px;
    margin: auto;
  }
  .pie-chart-container1 {
    width: 500px;
    height: 450px;
    margin: auto;
  }
  .table-container {
    height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .pie-chart-container-supply {
    width: 400px;
    height: 400px;
    display: flex;
    margin: auto;
  }
  .horizontal-bar-chart-container {
  }
}
